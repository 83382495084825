<template>

  <!-- Recommendation Form -->
  <j-form
    model="opps.recommendation"
    class="recommendation-form"
  >

    <!-- Recommended For -->
    <j-control name="recommended_slug" :cols="userCanAssign ? 8 : 12" />

    <!-- Assignment -->
    <j-control v-if="userCanAssign" name="assign" cols="4" />

    <!-- Message -->
    <j-control name="message" />

  </j-form>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('opps')

export default {
  name: 'RecommendationForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'recommendation'
    ]),
    // Policy
    userCanAssign () {
      // TODO - allow section editors to assign
      return this.$user.isEditor()
    }
  }
}
</script>

<template>

  <!-- Recommend Opp (Dialog) -->
  <j-dialog
    :value="$dialog.status('recommend-opp')"
    title="Recommend Opportunity"
    width="50%"
    class="recommend-opp"
    @cancel="handleCancelRecommendation"
  >
    <j-card-text>

      <!-- Opp Preview -->
      <OppTile
        :opp="opp"
        class="my-4"
      />

      <!-- Recommendation Form -->
      <RecommendationForm />

    </j-card-text>

    <!-- Actions -->
    <template #actions>

      <!-- Send -->
      <j-button
        label="Send"
        type="Send"
        color="primary"
        :processing="recommendation.saving"
        @action="handleConfirmRecommendation"
      />

    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!recommendation.saving"
        label="Cancel"
        type="option"
        @action="handleCancelRecommendation"
      />
    </template>

  </j-dialog>
</template>

<script>

// Components
import OppTile from '@/components/widgets/Opps/OppTile'
import RecommendationForm from '@/components/Opps/RecommendationForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'RecommendOpp',
  // -------------
  // Components ==
  // -------------
  components: {
    OppTile,
    RecommendationForm
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp',
      'recommendation'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'sendRecommendation'
    ]),
    handleCancelRecommendation() {
      this.$dialog.close('recommend-opp')
    },
    handleConfirmRecommendation() {
      this.sendRecommendation()
    }
  }
}
</script>

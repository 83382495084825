<template>

  <!-- Opps Listing -->
  <j-listing
    :listing="available"
    :loading="loading"
    heading="Available Opportunities"
    class="opps-listing"
    @selected="handleSelected"
    @more="handleMore"
  >
    <template #footer>

      <!-- Load More -->
      <div v-intersect.quiet="handleMore">
        <p v-if="available.length">
          Showing {{ available.length }} of {{ available.get('count') }} entries
        </p>
        <p v-if="loading">
          <j-icon
            icon="star"
            class="mr-1"
            spin
          />
          Loading...
        </p>
      </div>

    </template>
  </j-listing>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'OppsListing',
  // -------------
  // Properties ==
  // -------------
  props: {
    listing: {
      type: String,
      default: 'available'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp',
      'available'
    ]),
    // Getters
    ...mapGetters([
      'loading'
    ]),
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchMoreOpps',
      'selectOpp'
    ]),
    // Handlers
    handleSelected (index) {
      this.selectOpp({ listing: this.listing, index })
      this.$router.push({ name: 'opps-details', params: { tag19: this.opp.get('tag19') }})
    },
    handleMore (entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMoreOpps({ listing: this.listing })
      }
    }
  }
}
</script>

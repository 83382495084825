<template>

  <!-- Opp Tile Widget -->
  <j-tile
    :badge-url="opp.badge_src"
    :kicker="opp.section.name"
    :kicker-color="opp.section.highlight_color"
    :headline="opp.title"
    :headline-icon="opp.requested ? 'star' : ''"
    :description="opp.description"
    :tags="opp.tags"
    class="opp-tile-widget"
  >

    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Link -->
    <template #link v-if="opp.url">
      <a :href="opp.url" target="_blank">{{ opp.url }}</a>
    </template>

    <!-- Footer -->
    <template #footer>
      <slot name="footer" />
    </template>

  </j-tile>
</template>

<script>

export default {
  name: 'OppTileWidget',
  // -------------
  // Properties ==
  // -------------
  props: {
    opp: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

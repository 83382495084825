<template>

  <!-- Opps Index View -->
  <j-page
    :title="pageTitle"
    :title-prefix="pageTitlePrefix"
    :subtitle="pageSubtitle"
    :loading="loading"
    :left-cols="leftCols"
    fill
    id="opps-index-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search @expand="handleExpand" />

      <!-- Add Opp Button -->
      <j-button
        v-if="showAdd"
        type="icon"
        help="Add an opportunity to the site"
        icon="plus"
        :to="{ name: 'opps-create' }"
      />

    </template>

    <!-- Left - Available Opps -->
    <template #left>

      <!-- Opps Listing -->
      <OppsListing />

    </template>

    <!-- Right - Router View -->
    <template #right>

      <!-- Router View -->
      <router-view />

    </template>

    <!-- Dialogs -->
    <template #dialogs>
      
      <!-- Comment Dialog for retiring assignments -->
      <CancelAssignmentDialog />

      <!-- Recommend Opp Dialog -->
      <RecommendOppDialog />

    </template>
  </j-page>
</template>

<script>

// Components
import CancelAssignmentDialog from '@/components/Opps/CancelAssignmentDialog'
import OppsListing from '@/components/Opps/OppsListing'
import RecommendOppDialog from '@/components/Opps/RecommendOppDialog'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('opps')

// Helpers
import { capitalize } from 'lodash'

export default {
  name: 'OppsIndex',
  // -------------
  // Components ==
  // -------------
  components: {
    CancelAssignmentDialog,
    OppsListing,
    RecommendOppDialog
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.name === 'opps-featured') {
        // Fetch index (available opps)
        vm.fetchOpps({ listing: 'available' })
      }
      vm.setIndexVisibility({ to, from })
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === 'opps-featured') {
      // Fetch index (available opps)
      this.fetchOpps({ listing: 'available' })
    }
    this.setIndexVisibility({ to, from })
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Opportunities'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'available',
      'showIndex',
      'opp',
      'query'
    ]),
    // Getters
    ...mapGetters([
      'loading'
    ]),
    // UI
    pageTitle () {
      switch (this.$route.name) {
        case 'opps-create':
          return 'Add Opportunity'
        case 'opps-edit':
        case 'opps-details':
          return this.showIndex ? 'Opportunities' : (this.opp.$.title || 'Opportunities')
        default:
          return 'Opportunities'
      }
    },
    pageTitlePrefix () {
      const releaseDate = this.opp.releaseDate
      return releaseDate && !this.showIndex ? releaseDate : ''
    },
    pageSubtitle () {
      var subtitle
      switch (this.$route.name) {
        case 'opps-details':
          subtitle = capitalize(this.opp.stage)
          if (this.opp.assignee) {
            subtitle = `${subtitle} / ${this.opp.assignee.name}`
          }
          return this.showIndex ? null : subtitle
        case 'opps-search':
          return this.query.q ? `matching &laquo;${this.query.q}&raquo;` : ''
        default:
          return null
      }
    },
    leftCols () {
      return this.showIndex ? 4 : 0
    },
    searchMode () {
      return this.$route.name === 'opps-search'
    },
    showSearch () {
      return !['opps-create', 'opps-edit', 'opps-news-draft'].includes(this.$route.name)
    },
    showAdd () {
      return this.showSearch
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchOpps',
      'selectOpp',
      'setIndexVisibility'
    ]),
    // Handlers
    handleExpand () {
      this.$router.push({name: 'opps-search'})
    }
  }
}
</script>

<template>

  <!-- Cancel Assignment (Dialog) -->
  <j-dialog
    :value="$dialog.status('cancel-assignment')"
    title="Cancel Assignment"
    width="50%"
    class="cancel-assignment"
    @cancel="handleCancel"
  >
    <j-card-text>

      <!-- Opp Preview -->
      <OppTile
        :opp="opp"
        class="my-4"
      >
        <template #footer>
          Assigned to
          <j-label
            :title="opp.assignee.name"
            small
            class="ml-1 my-2"
          />
        </template>
      </OppTile>

    </j-card-text>

    <!-- Actions -->
    <template #actions>

      <!-- Confirm -->
      <j-button
        label="Cancel"
        type="confirm"
        :processing="opp.saving"
        @action="handleConfirm"
      />

    </template>

    <!-- Options -->
    <template #options>

      <!-- Close -->
      <j-button
        v-if="!opp.saving"
        label="Close"
        type="option"
        @action="handleCancel"
      />

    </template>
  </j-dialog>
</template>

<script>

// Components
import OppTile from '@/components/widgets/Opps/OppTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'CancelAssignmentDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    OppTile
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'cancelAssignment'
    ]),
    // Handlers
    handleConfirm () {
      this.cancelAssignment()
    },
    handleCancel () {
      this.$dialog.close('cancel-assignment')
    }
  }
}
</script>
